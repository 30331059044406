import React, { useEffect, useState } from 'react';

import { isBrowser } from '../helpers/isBrowser';
import { Face } from '../components/face';

const Bonus = () => {
	const [key, setKey] = useState('');

	const handleKeyPress = e => {
		setKey(e.keyCode);
	};

	const resetKey = () => setKey('');

	useEffect(() => {
		if(isBrowser()) {
			window.addEventListener('keydown', e => handleKeyPress(e));
			window.addEventListener('keyup', () => resetKey());
			return () => {
				window.removeEventListener('keydown', e => handleKeyPress(e));
				window.removeEventListener('keyup', () => resetKey());
			};
		}
	}, []);

	return (
		<div className="flex items-center justify-center min-h-screen bonus bg-secondary">
			<div className="w-330">
				<Face isAngry={key === 49} faceReaction={key === 50} browsUp={key === 51}/>
			</div>
		</div>
	);
};

export default Bonus;
